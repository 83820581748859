import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

export default props => (
  <Layout {...props}>
    <Seo title='About Us' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="About Us"
          subheader='I created this blog with Gatsby JS Framework. Hope you guys use this site lightning fast. In this blog I will share what I learn previously. I love Reading Technology, Personal Finance and Lifestyle Blog. Hope You Like this little blog'
        />
        <Divider />
      </Main>
    </Stack>
  </Layout>
)
